import React from 'react';
import { firebaseAuth } from './firebase';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo, getAuth } from 'firebase/auth';

function Login() {
	const navigate = useNavigate();

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
		provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    // Restrict to your Google Workspace domain
    provider.setCustomParameters({
       hd: "nanograb.com"  // Replace with your domain
    });


    signInWithPopup(firebaseAuth, provider)
    .then((result) => {
			// This gives you a Google Access Token. You can use it to access the Google API.
			// const credential = GoogleAuthProvider.credentialFromResult(result);
			// const token = credential.accessToken;
			// The signed-in user info.
			// const user = result.user;
			console.log(result);
			// IdP data available using getAdditionalUserInfo(result)
			const info = getAdditionalUserInfo(result);
			console.log(info);
			if (info.profile.hd !== 'nanograb.com') {
				navigate('/invalid')
			}
			navigate('/');
    }).catch((error) => {
			// Handle Errors here.
			//const errorCode = error.code;
			//const errorMessage = error.message;
			// The email of the user's account used.
			//const email = error.customData.email;
			// The AuthCredential type that was used.
			//const credential = GoogleAuthProvider.credentialFromError(error);
			// ...
    });
  };

  return (
    <div>
			<h1>{getAuth().currentUser ? getAuth().currentUser.displayName : "Hello!"}</h1>
      <button onClick={signInWithGoogle}>Sign in with Google</button>
    </div>
  );
}

export default Login;