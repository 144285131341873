import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Invalid() {
    const navigate = useNavigate();
    setTimeout(navigate, 5000, "/login", { replace: true });
    return (
        <div>
            <h1>Error - Invalid User!</h1>
        </div>
    );
}