import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { firebaseAuth } from './firebase';
import Home from './home';
import Login from './login';
import Invalid from './invalid';

function App() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
  });

  console.log(currentUser);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={currentUser ? <Home /> : <Navigate to="/login"/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/invalid" element={<Invalid />} />
        <Route path="*" element={<Navigate to={currentUser ? "/" : "/login"}/>} />
      </Routes>
    </BrowserRouter>  
  );
}

export default App;