import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import './App.css';

async function logCollection(collection) {
  const response = await fetch("http://localhost:5000/" + collection);
  const result = await response.text();
  console.log(result);
}

function Home() {

  useEffect(() => {
    logCollection("players");
    logCollection("matches");
  }, []);

  const [players] = useState(['Alice', 'Bob', 'Charlie', 'David']);
  const [player1, setPlayer1] = useState('');
  const [player2, setPlayer2] = useState('');
  const [player1Score, setPlayer1Score] = useState(0);
  const [player2Score, setPlayer2Score] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [history, setHistory] = useState([]);

  const startNewGame = () => {
    if (!player1 || !player2 || player1 === player2) {
      alert('Please select different players for each side.');
      return;
    }

    const game = {
      player1,
      player2,
      player1Score: Number(player1Score),
      player2Score: Number(player2Score),
    };

    setHistory([...history, game]);
    setPlayer1('');
    setPlayer2('');
    setPlayer1Score(0);
    setPlayer2Score(0);
  };

  const deleteGame = index => {
    setHistory(history.filter((_, i) => i !== index));
  };

  const editScore = (index, player1Score, player2Score) => {
    const updatedHistory = [...history];
    updatedHistory[index] = { ...updatedHistory[index], player1Score, player2Score };
    setHistory(updatedHistory);
  };

  const getUser = () => {
    const auth = getAuth();
    return auth.currentUser;
  }

  return (
    <div className="App container">
      <h1 className="text-2xl font-bold pt-14">Table Tennis Scoreboard ({getUser().displayName})</h1>
      <div>
        <select value={player1} onChange={e => setPlayer1(e.target.value)}>
          <option value="">Select Player 1</option>
          {players.map((player, index) => (
            <option key={index} value={player}>{player}</option>
          ))}
        </select>
        <input
          type="number"
          value={player1Score}
          onChange={e => setPlayer1Score(e.target.value)}
          placeholder="Player 1 Score"
        />
        <select value={player2} onChange={e => setPlayer2(e.target.value)}>
          <option value="">Select Player 2</option>
          {players.map((player, index) => (
            <option key={index} value={player}>{player}</option>
          ))}
        </select>
        <input
          type="number"
          value={player2Score}
          onChange={e => setPlayer2Score(e.target.value)}
          placeholder="Player 2 Score"
        />
        <button onClick={startNewGame}>Start New Game</button>
      </div>
      <div className="history">
        <h2>Game History</h2>
        <table>
          <thead>
            <tr>
              <th>Game</th>
              <th>Player 1</th>
              <th>Player 2</th>
              <th>Player 1 Score</th>
              <th>Player 2 Score</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {history.map((game, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{game.player1}</td>
                <td>{game.player2}</td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="number"
                      value={game.player1Score}
                      onChange={(e) => editScore(index, Number(e.target.value), game.player2Score)}
                    />
                  ) : (
                    game.player1Score
                  )}
                </td>
                <td>
                  {editIndex === index ? (
                    <input
                      type="number"
                      value={game.player2Score}
                      onChange={(e) => editScore(index, game.player1Score, Number(e.target.value))}
                    />
                  ) : (
                    game.player2Score
                  )}
                </td>
                <td>
                  <button onClick={() => setEditIndex(index)}>Edit</button>
                  <button onClick={() => deleteGame(index)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Home;
