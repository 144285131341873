// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-BWxtHcmgwLaMwBlK8BPmrQ8QVuyzinM",
  authDomain: "smash-3d895.firebaseapp.com",
  projectId: "smash-3d895",
  storageBucket: "smash-3d895.appspot.com",
  messagingSenderId: "18479492902",
  appId: "1:18479492902:web:f98bda55ddb7b7a3119be7",
  measurementId: "G-ZSR57SM08T"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export default firebaseApp